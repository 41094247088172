import React, { useState, useEffect } from 'react'
import { useLocation } from '@reach/router'
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { translate } from '../helpers/translate'
import { FormattedOrder } from '../types/generated/api.interface'
import { getPublicShopAPIUrl } from '../helpers/api-url'
import { getParametersFromQueryString } from '../helpers/query-string'
import { BasketProductList } from '../components/Basket/BasketProductList'
import { ErrorMessage } from '../components/ErrorMessage'
import { PriceTableRender } from '../components/BasketPriceTable'
import Layout from '../Layout/Layout'
import { usePageViewAnalytics } from '../hooks/useAnalytics'
import Seo from '../components/Seo'

const useStyles = makeStyles(theme => ({
  infoBlock: {
    padding: theme.spacing(5),
  },
  basketTotals: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginTop: theme.spacing(3),
      '& > *:first-child': {
        marginBottom: theme.spacing(3),
      },
    },
  },
  basketPriceTableWrapper: {
    width: theme.spacing(60),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

const OrderConfirmPage = () => {
  const { search } = useLocation()

  const classes = useStyles()
  const { orderId = '' } = getParametersFromQueryString(search)

  const [loading, setLoading] = useState(false)

  const [orderData, setOrderData] = useState<FormattedOrder | null>(null)
  const [error, setError] = useState(false)

  const items = orderData?.variantData?.map(product => {
    return {
      brand: product.brand,
      name: product.name,
      id: product.variantId,
      quantity: `${product.amount}`,
      price: `${product.unitPrice}`,
    }
  })

  // Adobe Analytics - Global Variables
  usePageViewAnalytics({
    trackingPageInfo: {
      pageName: 'receipt - shop',
      siteSection: 'shop',
      subSection1: 'betaling godkendt',
      pageType: 'receipt',
      id: 'receipt-shop',
      pageVariant: 'SMB',
    },
    trackingOrderInfo: {
      id: orderData?.transactionId,
      revenueExcludingVatAndDelivery: `${orderData?.totalExcludingVatAndFreight}`,
      revenue: `${orderData?.totalInclVat}`,
    },
    trackingProductsInfo: {
      customEvent: 'purchase',
      items,
    },
    dependency: [orderData?.transactionId],
  })

  useEffect(() => {
    setLoading(true)
    fetch(
      `${getPublicShopAPIUrl()}order?orderid=${encodeURIComponent(
        orderId as string,
      )}`,
    ).then(res => {
      if (res.status >= 400) {
        setLoading(false)
        setError(true)
        return
      }
      res
        .json()
        .then(json => {
          setOrderData(json)
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false))
    })
  }, [])
  if (loading) {
    return <CircularProgress />
  }

  if (error) {
    return (
      <Layout>
        <ErrorMessage
          title={translate('orderConfirmation.orderErrorHeader')}
          message={translate('orderConfirmation.orderErrorText')}
        />
      </Layout>
    )
  }

  return (
    <Layout>
      <Seo
        lang="da"
        title="Betaling er godkendt"
        description="Din betaling af produktet er nu godkendt"
      />
      <Box mb={2.5}>
        <Typography variant="h4" component="h1" align="center">
          {translate('orderConfirmation.headerTitle')}
        </Typography>
      </Box>
      <Typography
        variant="body1"
        component="div"
        align="center"
        dangerouslySetInnerHTML={{
          __html: translate('orderConfirmation.headerText', {
            email: orderData?.email || '',
            orderNumber: String(orderData?.orderNumber),
          }),
        }}
      />

      <Box mt={8} mb={6}>
        <Card className={classes.infoBlock}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Box mb={2}>
                <Typography variant="subtitle1">
                  {translate('orderConfirmation.invoiceInfo')}
                </Typography>
              </Box>
              <Typography variant="body1">
                {orderData?.invoiceAddress?.name}
                <br />
                {orderData?.invoiceAddress?.address},{' '}
                {orderData?.invoiceAddress?.city}
                <br />
                {orderData?.invoiceAddress?.country}
                <Box mt={3}>
                  {orderData?.invoiceAddress?.phone && (
                    <>
                      {translate('orderConfirmation.mobile')}{' '}
                      <strong>{orderData?.invoiceAddress?.phone}</strong>
                      <br />
                    </>
                  )}
                  {translate('orderConfirmation.email')}{' '}
                  <strong>{orderData?.invoiceAddress?.email}</strong>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box mb={2}>
                <Typography variant="subtitle1">
                  {translate('orderConfirmation.shippingInfo')}
                </Typography>
              </Box>
              <Typography variant="body1">
                {orderData?.deliveryAddress?.name}
                <br />
                {translate('orderConfirmation.attentionTo', {
                  attentionTo: orderData?.deliveryAddress?.attention || '',
                })}
                <br />
                {orderData?.deliveryAddress?.address},{' '}
                {orderData?.deliveryAddress?.city}
                <br />
                {orderData?.deliveryAddress?.country}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Box>
      <Box>
        <Typography variant="h5">
          {translate('orderConfirmation.yourOrder')}
        </Typography>
        <BasketProductList lines={orderData?.variantData} readonly />
        <Box className={classes.basketTotals}>
          <div className={classes.basketPriceTableWrapper}>
            <PriceTableRender
              subTotal={orderData?.formattedTotalExcludingVatAndFreight}
              discountAmount={orderData?.formattedDiscountAmount}
              freight={orderData?.formattedFreight}
              vat={orderData?.formattedVat}
              totalInclVat={orderData?.formattedTotalInclVat}
            />
          </div>
        </Box>
      </Box>
    </Layout>
  )
}

export default OrderConfirmPage
